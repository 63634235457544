import styled from "styled-components"
import icecream from "../static/assets/icecream.webp"
import thumbsup from "../static/assets/thumbsup.webp"
import menu from "../static/assets/menu.webp"

const StyledContainer = styled.div`
    width: 100%;
    height: auto;
    overflow: hidden;
    .checkboard {
        img {
            display: block;
        }
    }
    .callout-container {
        max-width: 114rem;
        margin: 5rem auto;
        padding: 0rem 3rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 3.5rem;
        & > div {
            flex: 1;
            a {
                text-decoration: none;
            }
            .image {
                width: 6.5rem;
                height: 6.5rem;
                &.ice-cream {
                    background: url(${icecream}) no-repeat center;
                    background-size: contain;
                }
                &.menu {
                    background: url(${menu}) no-repeat center;
                    background-size: contain;
                }
                &.thumbs-up {
                    background: url(${thumbsup}) no-repeat center;
                    background-size: contain;
                }
            }
            p {
                min-height: 10rem;
            }
        }
        @media screen and (max-width: 740px) {
            display: block;
            & > div {
                max-width: 35rem;
                margin: 0 auto 7.5rem auto;
                display: block;
                p {
                    min-height: auto;
                }
            }
        }
    }
    .sns-container {
        max-width: 85rem;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        & > div {
            flex: 1;
            h2 {
                font-family: ${(props) => props.theme.fonts.script};
                line-height: 1.6rem;
                color: ${(props) => props.theme.colors.primary};
            }
            h1 {
                line-height: 2rem;
            }
            h3 {
                color: ${(props) => props.theme.colors.primary};
                font-weight: normal;
            }
            .button-container {
                display: flex;
                gap: 1.5rem;
                & > div {
                    a {
                        text-decoration: none;
                    }
                }
            }
        }
    }
`

const Callout = () => {
    return (
        <StyledContainer>
            <div className="callout-container">
                <div>
                    <div className="image ice-cream"></div>
                    <h2>Nielsen's Famous Concrete</h2>
                    <p>Our frozen custard is made fresh all day long on site.</p>
                    <a className="button" href="#frozen-custard">
                        Learn more
                    </a>
                </div>
                <div>
                    <div className="image menu"></div>
                    <h2>100% Fresh!</h2>
                    <p>Our frozen custard never contains artificial sweeteners or preservatives. We make our frozen custard only with fresh ingredients.</p>
                    <a className="button" target="_blank" rel="noreferrer" href={require("../static/assets/menu.pdf")}>
                        See our menu
                    </a>
                </div>
                <div>
                    <div className="image thumbs-up"></div>
                    <h2>Frozen to Perfection!</h2>
                    <p>Our frozen custard is served at 26 degrees which will not freeze your taste buds and allow you to taste our fresh ingredients. Yum!</p>
                    <a className="button" href="#about-us">
                        About us
                    </a>
                </div>
            </div>
        </StyledContainer>
    )
}

export default Callout
